import styles from './styles.module.scss'
import responsiveStyles from './responsiveStyles.module.scss'
import { SignaturePad, SubmitButton, Wrapper } from '../reusable'
import { IsMobile } from '../layout'

import axios from 'axios'
import { Report } from 'notiflix/build/notiflix-report-aio'
import { apiErrors } from '../../helpers/apiErrors'
import { browserName, osName, deviceDetect, getUA } from 'react-device-detect'
import { useEffect, useState } from 'react'
import Previewer from '../reusable/previewer'
import { Loading } from 'notiflix'
import Modal from '../reusable/modal'

export default function OpenLetterContent_FOIA() {
    const [formDataCollected, setFormData] = useState<any>({
        firstname: '',
        lastname: '',
        email: '',
        company: '',
    })
    const [previewUrl, setPreviewUrl] = useState<any>('')
    const [modalState, setModalState] = useState(false)

    useEffect(() => {
        if (!previewUrl.length) {
            Loading.standard('Loading...')
            axios
                .post(`${process.env.REACT_APP_API_TOKEN_URL}/open-letter-foia`, {})
                .then((response) => {
                    const responseData = response.data
                    console.log(responseData)
                    setPreviewUrl(responseData.previewUrl)
                    Loading.remove()
                })
                .catch((e) => {
                    console.error('error:', e)
                    const serverError = e.response.data
                    Loading.remove()
                    apiErrors(serverError)
                })
        }
    }, [])

    const handleChange = (e: any) => {
        let tempFormData = formDataCollected
        tempFormData[e.target.name] = e.target.value
        setFormData(tempFormData)
    }
    const completeOpenLetter = () => {
        Report.success('Thank you', 'Thank you for signing our open letter.', 'Okay', () => {
            window.location.href = 'https://sentinellegal.co.uk'
        })
        return
    }

    const handlePreviewModal = () => {
        setModalState(!modalState)
    }
    return (
        <div className={`${styles.openLetterContent} ${responsiveStyles.openLetterContent}`}>
            <Wrapper>
                <form>
                    <br />
                    <Previewer url={previewUrl} />
                    <br />
                    <br />
                    <p>Complete and sign the form below to contribute. </p>
                    <div className={styles.names}>
                        <fieldset>
                            <label>Firstname</label>
                            <input name="firstname" type={'text'} onChange={(e) => handleChange(e)} />
                        </fieldset>
                        <fieldset>
                            <label>Lastname</label>
                            <input name="lastname" type={'text'} placeholder="" onChange={(e) => handleChange(e)} />
                        </fieldset>
                    </div>
                    <fieldset>
                        <label>Email Address</label>
                        <input name="email" type={'email'} placeholder="" onChange={(e) => handleChange(e)} />
                    </fieldset>
                    <fieldset>
                        <label>Your Company</label>
                        <input name="company" type={'text'} placeholder="" onChange={(e) => handleChange(e)} />
                    </fieldset>
                </form>

                <SignaturePad
                    additionalAction={completeOpenLetter}
                    extraPayload={{
                        type: 'open-letter-foia-signature',
                        signerData: formDataCollected,
                        metadata: { browser: browserName, device: osName },
                    }}
                />
            </Wrapper>
        </div>
    )
}

import styles from '../globals/pages.module.scss'
import pageStyles from './styles.module.scss'

import { useEffect, useState } from 'react'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import { Loading } from 'notiflix'
import { Report } from 'notiflix/build/notiflix-report-aio'

import { Wrapper } from '../components/reusable'
import CfaBanner from '../components/cfaBanner'
import SingleSignatureContainer from '../components/singleSigContainer'
import { apiErrors } from '../helpers/apiErrors'
import PocView from '../components/pocView'
import { useUpdateClaimData } from '../claimProvider'
import { browserName, osName, deviceDetect, getUA } from 'react-device-detect'
import LoaBanner from '../components/loaBanner'
import { InputFieldAddressLookup } from '../components/reusable/inputFieldAddressLookup'
import { InputFieldText } from '../components/reusable/inputFieldText'
import { Field } from '../components/reusable/field'
import { InputFieldDate } from '../components/reusable/inputFieldDate'

export default function DsarLoaDobConf() {
    const [loaData, setLoaData] = useState([])
    const [retrievedDsarLoa, setRetrievedDsarLoa] = useState(false)
    const [currentClaim, setCurrentClaim]: any = useState({})
    const { token } = useParams()
    const updateClaim = useUpdateClaimData()

    const [dataToSave, setDataToSave] = useState<any>({})
    const [AddressText, setAddressText] = useState('')
    const [AddressOne, setAddressOne] = useState('')
    const [AddressTwo, setAddressTwo] = useState('')
    const [AddressThree, setAddressThree] = useState('')
    const [AddressCity, setAddressCity] = useState('')
    const [AddressPostcode, setAddressPostcode] = useState('')

    useEffect(() => {
        if (!retrievedDsarLoa) {
            Loading.standard('Loading...')
            axios
                .post(`${process.env.REACT_APP_API_TOKEN_URL}/dsar-loa-dob-conf`, { token })
                .then((response) => {
                    const responseData = response.data
                    if (responseData == 'LOA Complete') {
                        Report.success('Document Signed', 'Your Document has already been signed.', 'Okay', () => {
                            window.location.href = 'https://sentinellegal.co.uk'
                        })
                    }

                    const signatureData = responseData.sigData
                    console.log(signatureData)

                    if (!signatureData) throw new Error(responseData)
                    localStorage.setItem('name', `${signatureData.first_name} ${signatureData.last_name}`)

                    setLoaData(signatureData)
                    setCurrentClaim(signatureData)
                    setRetrievedDsarLoa(true)
                    updateClaim(signatureData)

                    Loading.remove()
                })
                .catch((e) => {
                    console.error('error:', e)
                    const serverError = e.response.data
                    Loading.remove()
                    apiErrors(serverError)
                })
        }
    }, [])

    useEffect(() => {
        console.log('data to save', dataToSave)
    }, [dataToSave])

    const completeCfa = (obj: any) => {
        Report.success(
            'Letter of Authority Complete',
            'Thank you for signing your document. We will update you on your cases in due course.',
            'Okay',
            () => {
                window.location.href = 'https://sentinellegal.co.uk'
            }
        )

        return
    }

    const save = (updateData: any) => {
        if (updateData.field == 'searchAddress') {
            saveAddress(updateData.value)
            return { success: true, error: '' }
        }

        if (updateData.field == 'dob') {
            let tempData = dataToSave
            tempData[updateData.field] = updateData.value
            setDataToSave({ ...tempData })
        }

        return { success: true, error: '' }
    }

    const saveAddress = (addressString: string) => {
        setAddressText(addressString)
        const addressArr = addressString.split(', ')

        // console.log('ADDRESS LENGTH: ', addressArr.length)

        let addressLineOne = addressArr[0]
        let addressLineTwo = addressArr.length > 3 ? addressArr[1] : ''
        let addressLineThree = addressArr.length > 4 ? addressArr[addressArr.length - 3] : ''
        let city = addressArr[addressArr.length - 2]
        let postcode = addressArr[addressArr.length - 1]

        let tempStepData = dataToSave
        console.log('temp', tempStepData)

        setAddressOne(addressLineOne)
        tempStepData.address_line_one = addressLineOne

        setAddressTwo(addressLineTwo)
        tempStepData.address_line_two = addressLineTwo

        setAddressThree(addressLineThree)
        tempStepData.address_line_three = addressLineThree

        setAddressCity(city)
        tempStepData.city = city

        setAddressPostcode(postcode)
        tempStepData.postcode = postcode

        setDataToSave(tempStepData)
    }
    return (
        <div className={`${styles.pageContent} ${styles.pocContent}`}>
            <LoaBanner />
            <Wrapper>
                <div>
                    <form>
                        <div style={{ maxWidth: '1200px', margin: '0 auto' }}>
                            <h2>Additional Information Required to Progress Your Case</h2>
                            <p>
                                For Sentinel Legal to progress your case to the next stages, we require some additional
                                information from you, this includes you current address and confirmation of your date of
                                birth.
                            </p>
                            <p>
                                Please confirm your date of birth using the date selection box and provide your current
                                address using our address lookup service below. Finally, sign your Letter of Authority,
                                so we can
                            </p>
                            <hr />
                            <div className={styles.extendDatePicker}>
                                <h3>Select your date of birth </h3>
                                <InputFieldDate fieldName={'dob'} saveValue={save} />
                            </div>
                            <h3>Start typing your address to search</h3>
                            <InputFieldAddressLookup fieldName={`searchAddress`} saveValue={save} />

                            {AddressText.length > 0 && (
                                <>
                                    <br />
                                    <hr />
                                    <br />

                                    <Field
                                        extraStyle={{ padding: '0' }}
                                        fieldLabel="Address Line 1"
                                        labelPosition="left"
                                    >
                                        <InputFieldText
                                            fieldName="address_line_one"
                                            saveValue={save}
                                            populateValue={AddressOne}
                                        />
                                    </Field>
                                    <Field
                                        extraStyle={{ padding: '0' }}
                                        fieldLabel="Address Line 2"
                                        labelPosition="left"
                                    >
                                        <InputFieldText
                                            fieldName="address_line_two"
                                            saveValue={save}
                                            populateValue={AddressTwo}
                                        />
                                    </Field>
                                    <Field
                                        extraStyle={{ padding: '0' }}
                                        fieldLabel="Address Line 3"
                                        labelPosition="left"
                                    >
                                        <InputFieldText
                                            fieldName="addres_line_three"
                                            saveValue={save}
                                            populateValue={AddressThree}
                                        />
                                    </Field>
                                    <Field extraStyle={{ padding: '0' }} fieldLabel="City" labelPosition="left">
                                        <InputFieldText fieldName="city" saveValue={save} populateValue={AddressCity} />
                                    </Field>
                                    <Field extraStyle={{ padding: '0' }} fieldLabel="Postcode" labelPosition="left">
                                        <InputFieldText
                                            fieldName="postcode"
                                            saveValue={save}
                                            populateValue={AddressPostcode}
                                        />
                                    </Field>
                                </>
                            )}
                        </div>
                    </form>
                </div>
            </Wrapper>
            <Wrapper>
                <PocView>
                    {retrievedDsarLoa ? (
                        <>
                            <SingleSignatureContainer
                                signedAction={completeCfa}
                                previewUrl={currentClaim.doc_url}
                                extraPayload={{
                                    type: 'dsar-loa-dob-conf',
                                    metadata: { browser: browserName, device: osName },
                                    clientData: { ...dataToSave },
                                }}
                                disclaimer={
                                    'We will carry out a soft credit check to validate your case as well as verify your identity and prevent money laundering. This will not impact your credit rating in any way and only data relevant to Vehicle finance will be gathered from your file'
                                }
                            />
                        </>
                    ) : (
                        <></>
                    )}
                </PocView>
            </Wrapper>
        </div>
    )
}

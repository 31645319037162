import styles from '../globals/pages.module.scss'
import pageStyles from './styles.module.scss'

import { useEffect, useState } from 'react'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import { Loading } from 'notiflix'
import { Report } from 'notiflix/build/notiflix-report-aio'
import { browserName, osName, deviceDetect, getUA } from 'react-device-detect'

import { Wrapper } from '../components/reusable'
import CfaBanner from '../components/cfaBanner'
import SingleSignatureContainer from '../components/singleSigContainer'
import { apiErrors } from '../helpers/apiErrors'
import PocView from '../components/pocView'
import { useUpdateClaimData } from '../claimProvider'
import LCCfaBanner from '../components/LCCfaBanner'
import DualSignatureContainer from '../components/dualSigContainer'

export default function CfaLC() {
    const [cfaData, setCfaData] = useState([])
    const [retrievedCfa, setRetrievedCfa] = useState(false)
    const [currentClaim, setCurrentClaim]: any = useState({})
    const { token } = useParams()
    const updateClaim = useUpdateClaimData()
    useEffect(() => {
        if (!retrievedCfa) {
            Loading.standard('Loading...')
            axios
                .post(`${process.env.REACT_APP_API_TOKEN_URL}/lc-cfa`, { token })
                .then((response) => {
                    const responseData = response.data
                    if (responseData == 'CFA Complete') {
                        Report.success('Document Signed', 'Your Document has already been signed.', 'Okay', () => {
                            window.location.href = 'https://sentinellegal.co.uk'
                        })
                    }

                    const signatureData = responseData.sigData
                    console.log(signatureData)

                    if (!signatureData) throw new Error(responseData)
                    localStorage.setItem('name', `${signatureData.first_name} ${signatureData.last_name}`)

                    setCfaData(signatureData)
                    setCurrentClaim(signatureData)
                    setRetrievedCfa(true)
                    updateClaim(signatureData)

                    Loading.remove()
                })
                .catch((e) => {
                    console.error('error:', e)
                    const serverError = e.response.data
                    Loading.remove()
                    apiErrors(serverError)
                })
        }
    }, [])

    const completeCfa = (obj: any) => {
        Report.success(
            'Contingency Fee Agreement Complete',
            'Thank you for signing your document. We will update you on your cases in due course.',
            'Okay',
            () => {
                window.location.href = 'https://sentinellegal.co.uk'
            }
        )

        return
    }

    return (
        <div className={`${styles.pageContent} ${styles.pocContent}`}>
            <LCCfaBanner />
            <Wrapper>
                <div>
                    <form>
                        <div style={{ maxWidth: '900px', margin: '0 auto' }}>
                            <h2
                                style={{
                                    color: '#333',
                                    fontSize: '20px',
                                    textAlign: 'center',
                                    margin: '40px auto 20px',
                                }}
                            >
                                You could get back thousands of pounds from your mis-sold car finance deal!
                            </h2>
                            <span
                                style={{
                                    width: '80px',
                                    margin: '0 auto 30px',
                                    display: 'block',
                                    height: '2px',
                                    backgroundColor: '#c78f5f',
                                }}
                            ></span>
                            <p style={{ textAlign: 'center', fontSize: '16px' }}>
                                After looking at the information you gave us, we think you might have a chance to make a
                                claim for mis-sold car finance.
                            </p>
                            <p style={{ textAlign: 'center', fontSize: '16px' }}>
                                Sentinel Legal will check this for free. If we find you can make a claim, we’ll work to
                                get your money back—and you won’t pay us anything unless we win!
                            </p>
                            <hr />
                        </div>
                    </form>
                </div>
                <PocView>
                    {retrievedCfa ? (
                        <DualSignatureContainer
                            signedAction={completeCfa}
                            leftPreviewUrl={currentClaim.cfa_doc_url}
                            rightPreviewUrl={currentClaim.loa_doc_url}
                            extraPayload={{
                                type: 'lc-cfa-signature',
                                metadata: { browser: browserName, device: osName, routedBy: 'CF' },
                            }}
                            disclaimer={
                                '*By signing this document, you agree that Sentinel Legal can do a soft credit check to look at your car finance deal, check who you are, and stop money laundering. This check won’t affect your credit score, and we will only look at information about your car finance. You also agree that Sentinel Legal can use your signature to complete all claims we find in your name. This will allow us to fill out and send the necessary forms for each claim. Your signature will only be used for claims linked to you, and you can ask us to stop using it at any time. '
                            }
                        />
                    ) : (
                        <></>
                    )}
                </PocView>
            </Wrapper>
        </div>
    )
}

// import React, { useState, useEffect } from "react";
import styles from './styles.module.scss'

import Previewer from '../reusable/previewer'
import { SignaturePad } from '../reusable'
interface sigProps {
    leftPreviewUrl: any
    rightPreviewUrl: any
    signedAction: any
    extraPayload: any
    disclaimer?: String
}

export default function DualSignatureContainer({
    leftPreviewUrl,
    rightPreviewUrl,
    signedAction,
    extraPayload,
    disclaimer,
}: sigProps) {
    return (
        <div className={styles.dualSigContainer}>
            <div className={`${styles.previews} `}>
                <Previewer url={leftPreviewUrl} />
                <Previewer url={rightPreviewUrl} />
            </div>
            <SignaturePad additionalAction={signedAction} extraPayload={extraPayload} disclaimer={disclaimer} />
        </div>
    )
}

import styles from '../globals/pages.module.scss'
import { useEffect } from 'react'
import OpenLetterBanner_FOIA from '../components/openLetterBanner_FOIA'
import OpenLetterContent_FOIA from '../components/openLetterContent_FOIA'

export default function OpenLetterFca__FOIA() {
    useEffect(() => {}, [])

    return (
        <div className={` ${styles.openLetterContent}`}>
            <OpenLetterBanner_FOIA />
            <OpenLetterContent_FOIA />
        </div>
    )
}
